import React from 'react'

const About = () => {
  return (
    <div className='flex justify-center pt-12 sm:pt-0 sm:pb-24 px-4' name='about'>
      <div className='sm:w-[75%] flex flex-col gap-8'>
        <h1 className='text-4xl font-bold'>About</h1>
        <p className='text-xl'>
          In the vast digital realm, I'm a web virtuoso with a diploma from ISTA and currently pursuing my bachelor's degree. Proficient in 
          several web technologies that you can find down bellow, I sculpt immersive experiences that transcend functionality. Seeking opportunities to innovate 
          and collaborate, I'm poised to elevate the digital landscape with creativity and precision.
        </p>
      </div>
    </div>
  )
}

export default About