import React from 'react'
import heroImage from '../assets/heroImage.png'
import { BsArrowRight } from 'react-icons/bs'
import { Link } from 'react-scroll'

const Home = () => {
  return (
      <div className='flex flex-col sm:flex-row sm:justify-center gap-4 sm:h-screen items-center' name='home'>
        <div className='bg-cyan-500 rounded-md h-fit sm:hidden block'>
          <img width='350px' src={heroImage} alt='My profile' />
        </div>
        <div className='flex flex-col justify-center gap-8 sm:w-1/2 px-4 sm:p-0'>
          <h1 className="text-4xl sm:text-6xl font-bold">Full Stack Developer</h1>
          <p className='text-xl'>
            Hi, My name is Saad and I'm a Full Stack developer. It's been 3 years since i started web development and I just love working on web applications using technologies like React JS, Laravel and Tailwind.
          </p>
          <Link to='portfolio' smooth duration={500}><button className='flex items-center gap-2 bg-cyan-500 hover:bg-cyan-600 duration-150 p-2 rounded-md group'>Portfolio <span className='group-hover:rotate-90 duration-200'><BsArrowRight /></span></button></Link>
        </div>
        <div className='bg-cyan-500 rounded-md h-fit sm:block hidden'>
          <img width='350px' src={heroImage} alt='My profile' />
        </div>
      </div>
  )
}

export default Home