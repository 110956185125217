import React from "react";
import { FaFacebook, FaInstagram } from "react-icons/fa";

const Contact = () => {
  return (
    <div className="flex justify-center py-12" name="contact">
      <div className="sm:w-[75%] px-4 flex flex-col gap-8">
        <h1 className="text-4xl font-bold">Contact</h1>
        <p className="text-xl">Get in touch with me right now :</p>
        <div className="flex flex-col gap-8">
          <h3>
            <span className="font-semibold">Email : </span>
            zearkill2@gmail.com
          </h3>
          <h3>
            <span className="font-semibold">Phone : </span>
            0707734426
          </h3>
          <h3>
            <span className="font-semibold">Whatsapp : </span>
            0612245245
          </h3>
          <h3 className="flex">
            <span className="font-semibold mr-2">Social Media : </span>
            <div className="flex gap-4 items-center">
              <FaFacebook className="hover:text-blue-500 duration-150 text-xl" />
              <FaInstagram className="hover:text-red-400 duration-150 text-xl" />
            </div>
          </h3>
        </div>
      </div>
    </div>
  );
};

export default Contact;
