import React from 'react'
import { portfolio } from './Utilities'


const Portfolio = () => {
    return ( 
    <div className='flex justify-center py-12' name='portfolio'>
      <div className='sm:w-[75%] px-4 flex flex-col gap-8'>
        <h1 className='text-4xl font-bold'>Portfolio</h1>
        <p className='text-xl'>Check out some of my work right here :</p>
        <div className='flex flex-col gap-4 sm:grid grid-cols-3 sm:gap-8'>
          {portfolio.map(e => <div key={e.id} className={`border border-gray-500 rounded-md group hover:scale-105 hover:shadow-md hover:shadow-gray-500 duration-150`}>
            <a href={e.link} target='blank' className=''>
              <img className='sm:h-[200px] rounded-t-md' src={e.image} alt="logo du demo" />
              <div className='text-center p-4 group-hover:bg-gray-500 duration-150 rounded-b-md'>
                <button className=''>Demo</button>
              </div>
            </a>
          </div>)}
        </div>
      </div>
    </div>
  )
}

export default Portfolio