import css from '../assets/css.png';
import html from '../assets/html.png';
import javascript from '../assets/javascript.png';
import react from '../assets/react.png';
import tailwind from '../assets/tailwind.png';
import Laravel from '../assets/Laravel.png';
import github from '../assets/github.png';
import wordpress from '../assets/wordpress.png';

import digideal from '../assets/portfolio/Digideals.jpg'

const portfolio = [
    {
        id : 1,
        image : digideal,
        link : 'https://Digideals.tv',
        color : 'fed700'
    }
]

const experience = [
    {
        id : 1,
        text : 'CSS',
        image : css,
        bgcolor : 'border-[#029ae5]',
        textcolor : 'text-[#029ae5]',
        shadowcolor : 'shadow-[#029ae5]'
    },
    {
        id : 2,
        text : 'HTML',
        image : html,
        bgcolor : 'border-[#f06429]',
        textcolor : 'text-[#f06429]',
        shadowcolor : 'shadow-[#f06429]'
    },
    {
        id : 3,
        text : 'JavaScript',
        image : javascript,
        bgcolor : 'border-[#fed700]',
        textcolor : 'text-[#fed700]',
        shadowcolor : 'shadow-[#fed700]'
    },
    {
        id : 4,
        text : 'React',
        image : react,
        bgcolor : 'border-[#00d8ff]',
        textcolor : 'text-[#00d8ff]',
        shadowcolor : 'shadow-[#00d8ff]'
    },
    {
        id : 5,
        text : 'Tailwind',
        image : tailwind,
        bgcolor : 'border-[#44a8b3]',
        textcolor : 'text-[#44a8b3]',
        shadowcolor : 'shadow-[#44a8b3]'
    },
    {
        id : 6,
        text : 'Laravel',
        image : Laravel,
        bgcolor : 'border-[#fe2e21]',
        textcolor : 'text-[#fe2e21]',
        shadowcolor : 'shadow-[#fe2e21]'
    },
    {
        id : 7,
        text : 'Github',
        image : github,
        bgcolor : 'border-[#ececec]',
        textcolor : 'text-[#ececec]',
        shadowcolor : 'shadow-[#ececec]'
    },
    {
        id : 8,
        text : 'Wordpress',
        image : wordpress,
        bgcolor : 'border-[#2f8bb5]',
        textcolor : 'text-[#2f8bb5]',
        shadowcolor : 'shadow-[#2f8bb5]'
    }
]

const links = [
    {
        id : 1,
        link : 'home',
    },
    {
        id : 2,
        link : 'about',
    },
    {
        id : 3,
        link : 'portfolio',
    },
    {
        id : 4,
        link : 'experience',
    },
    {
        id : 5,
        link : 'contact',
    },
]

export { links,experience,portfolio }