import React from 'react'
import { experience } from './Utilities'


const Experience = () => {
  return (
    <div className='flex justify-center py-12' name='experience'>
      <div className='sm:w-[75%] px-4 flex flex-col gap-8'>
        <h1 className='text-4xl font-bold'>Experience</h1>
        <p className='text-xl'>These are the technologies I worked with :</p>
        <div className='flex flex-col gap-4 sm:grid grid-cols-3 sm:gap-8'>
          {
            experience.map(e => <div key={e.id} className={`hover:scale-105 duration-200 flex flex-col justify-center items-center gap-4 py-4 shadow-md border ${e.bgcolor} ${e.shadowcolor} rounded-xl`}>
              <img src={e.image} alt={`${e.text}`} width={'100px'} height={'100px'} />
              <p className={`text-center text- ${e.textcolor}`}>{e.text}</p>
            </div>)
          }
        </div>
      </div>
    </div>
  )
}

export default Experience