import React from 'react'
import { useState } from 'react'
import { FaBars, FaTimes } from 'react-icons/fa'
import { Link } from 'react-scroll'
import { links } from './Utilities'

export default function Navbar() {

  const [nav, setNav] = useState(true)

  return (
    <div className='flex justify-between z-10 items-center w-full h-20 text-white fixed bg-black px-4 border-b-gray-800'>
        <div>
            <h1 className='text-5xl font-signature ml-2'>Saad</h1>
        </div>
        <ul className='hidden md:flex'>
            {links.map(({id, link}) => (
                <li key={id} className='px-4 cursor-pointer uppercase font-medium text-gray-500 hover:scale-105 hover:text-white duration-200'>
                    <Link to={link} smooth duration={500}>
                        {link}
                    </Link>
                </li>
            ))}
        </ul>
            <div className='cursor-pointer pr-4 z-10 text-gray-500 flex md:hidden' onClick={() => setNav(!nav)}>
                {nav ? <FaBars size={30} /> : <FaTimes size={30} />}
            </div>
            {nav ? <></> : 
                <ul className='flex md:hidden flex-col justify-center items-center absolute top-0 left-0 w-full h-screen bg-gradient-to-b from-black via-black to-gray-800 gap-6 scale-150'>
                    {links.map(({id, link}) => (
                        <li key={id} className='px-4 cursor-pointer capitalize font-medium text-gray-500 hover:scale-105 hover:text-white duration-200'>
                            <Link onClick={() => setNav(!nav)} to={link} smooth duration={500}>
                                {link}
                            </Link>
                        </li>
                    ))}
                </ul>
            }
    </div>  
  )
}