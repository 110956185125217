import About from "./components/About";
import Contact from "./components/Contact";
import Experience from "./components/Experience";
import Home from "./components/Home";
import Navbar from "./components/Navbar";
import Portfolio from "./components/Portfolio";

function App() {
  return (
    <div className="bg-gradient-to-b from-black to-gray-800 text-white">
      <Navbar />
      <div className="bg-gradient-to-b from-black to-gray-800 relative top-24">
        <Home />
        <About />
        <Portfolio />
        <Experience />
        <Contact />
      </div>
    </div>
  );
}

export default App;
